import type { NextPage } from "next";
import { Layout } from "../components/Layout";
import { gql, useLazyQuery } from "@apollo/client";
import { CzechiaMap } from "../components/CzechiaMap";
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Wrap,
} from "@chakra-ui/react";
import { Card } from "../components/Card";
import React, { FC, useMemo } from "react";
import { usePagination } from "react-use-pagination";
import { Pagination } from "../components/Pagination";
import { Acreage, numberFormatter } from "../utils/formatters";
import { withAuth } from "../features/auth/withAuth";

const GET_COUNTY_BY_NAME = gql`
  query CountyByName($name: String!) {
    county(where: { name: { _eq: $name } }, limit: 1) {
      id
      name
      catastral_territories {
        id
        name
        parcels_aggregate(where: { _and: { type: { _in: [2, 7, 14] } } }) {
          aggregate {
            sum {
              acreage
            }
          }
        }
      }
    }
    progress: county(where: { name: { _eq: $name } }, limit: 1) {
      catastral_territories {
        name
        done: parcels_aggregate(
          where: { lv_id: { _is_null: false }, non_existent_lv: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
        all: parcels_aggregate(where: { non_existent_lv: { _eq: false } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

const CatastralTerritoryTable: FC<{ data: any[] }> = ({ data }) => {
  const paginationProps = usePagination({
    totalItems: data.length,
    initialPageSize: 22,
  });

  return (
    <>
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>Kód</Th>
            <Th>Název</Th>
            <Th isNumeric={true}>
              <div>Výměra</div>
              <small>(orná půda, trvalý travní porost, ostatní plocha)</small>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data
            .slice(paginationProps.startIndex, paginationProps.endIndex + 1)
            .map((cat: any) => {
              return (
                <Tr key={cat.id}>
                  <Td>{cat.id}</Td>
                  <Td>{cat.name}</Td>
                  <Td isNumeric={true}>
                    <Acreage
                      acreage={cat.parcels_aggregate.aggregate.sum.acreage}
                    />
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {paginationProps.totalPages > 1 && <Pagination {...paginationProps} />}
    </>
  );
};

const Home: NextPage = () => {
  const [fetch, { data }] = useLazyQuery<{
    county: {
      id: string;
      name: string;
      catastral_territories: any[]; // TODO
    }[];
    progress: {
      catastral_territories: any[];
    }[];
  }>(GET_COUNTY_BY_NAME);

  const countyProgress = useMemo(
    () =>
      data
        ? data.progress.reduce(
            ({ all, done }: { all: number; done: number }, county) => {
              const ctParcels = county.catastral_territories.reduce(
                ({ all, done }: { all: number; done: number }, ct) => ({
                  done: done + ct.done.aggregate.count,
                  all: all + ct.all.aggregate.count,
                }),
                { all: 0, done: 0 }
              );
              return {
                all: all + ctParcels.all,
                done: done + ctParcels.done,
              };
            },
            { all: 0, done: 0 }
          )
        : null,
    [data]
  );

  return (
    <Layout>
      <Card
        minHeight={"calc(100vh - (var(--chakra-space-5) * 2))"}
        overflowX={"auto"}
        mt={1}
      >
        <Grid
          gridTemplateColumns={{ base: "1fr", xl: "1fr 2px 1fr" }}
          gridGap={10}
        >
          <Wrap w={"full"}>
            <Flex w={"full"} gap={5} alignItems={"center"}>
              <Flex w={"full"} justifyContent={"space-between"} gap={5}>
                <Stat flexGrow={0}>
                  <StatLabel whiteSpace={"nowrap"}>Celková výměra</StatLabel>
                  <StatNumber whiteSpace={"nowrap"}>
                    78,375 km<sup>2</sup>
                  </StatNumber>
                </Stat>
                <Stat flexGrow={0}>
                  <StatLabel whiteSpace={"nowrap"}>Celkem pozemků</StatLabel>
                  <StatNumber whiteSpace={"nowrap"}>22 191 488</StatNumber>
                </Stat>
                <Stat flexGrow={0}>
                  <StatLabel whiteSpace={"nowrap"}>Stav stahování</StatLabel>
                  <StatNumber whiteSpace={"nowrap"}>100&nbsp;%</StatNumber>
                </Stat>
              </Flex>
            </Flex>
            <Divider />
            <CzechiaMap
              onDistrictClick={async (name) => {
                await fetch({ variables: { name } });
              }}
            />
          </Wrap>
          <Divider orientation={"vertical"} />
          <Wrap w={"full"}>
            {data && (
              <Box w={"full"}>
                <Flex gap={5} pb={2}>
                  <Heading whiteSpace={"nowrap"} size={"xl"} mt={1}>
                    {data.county[0].name}
                  </Heading>
                  {countyProgress && (
                    <Box w={"full"}>
                      <small>
                        Stav stahování:&nbsp;
                        <strong>
                          {numberFormatter.format(
                            (countyProgress.done / countyProgress.all) * 100
                          )}
                          %
                        </strong>
                      </small>
                      <Progress
                        rounded={"lg"}
                        size={"lg"}
                        isAnimated={true}
                        hasStripe
                        value={(countyProgress.done / countyProgress.all) * 100}
                      />
                    </Box>
                  )}
                </Flex>
                <Divider mb={2} />
                <CatastralTerritoryTable
                  data={data.county?.[0].catastral_territories || []}
                />
              </Box>
            )}
          </Wrap>
        </Grid>
      </Card>
    </Layout>
  );
};

export default withAuth(Home);
export { chunk } from "./api/utils";
export { streamToString } from "./api/utils";
export { filterUniqAddressesOnLv } from "./api/utils/filterUniqAddressesOnLv";
