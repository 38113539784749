import React from "react";

export const numberFormatter = new Intl.NumberFormat("cs", {
  maximumFractionDigits: 2,
});

export const Acreage = ({ acreage }: { acreage: number }) => (
  <>
    {numberFormatter.format(acreage)}&nbsp;
    <small>
      m<sup>2</sup>
    </small>
  </>
);
