// @ts-ignore
import { isWoman } from "vokativ";
import { Stream } from "stream";

export const isWomanComplex = (fullname: string) => {
  const nameWithoutTitles = fullname
    .replace(/(\set\s)|([a-zA-Z]+(?=\.)\.)|([A-Z]{2,3})|(Ing|Mgr|Mpa)/g, "")
    .trim();
  const nameTokens = nameWithoutTitles.split(" ");
  const [lastname] = nameTokens;
  const firstname = nameTokens[nameTokens.length - 1];

  if (
    nameTokens.some((name) => name.trim().endsWith("ová")) ||
    lastname.endsWith("á")
  ) {
    return true;
  }

  return isWoman(firstname);
};

export function streamToString(stream: Stream): Promise<string> {
  const chunks: Buffer[] = [];
  return new Promise((resolve, reject) => {
    stream.on("data", (chunk) => chunks.push(Buffer.from(chunk)));
    stream.on("error", (err) => reject(err));
    stream.on("end", () => resolve(Buffer.concat(chunks).toString("utf8")));
  });
}

export const chunk = <T>(array: T[], chunkSize: number = 1): T[][] => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export function uniqBy<T extends object = object>(
  arr: T[],
  getKey: (x: T) => any
) {
  const seen = new Set();
  return arr.filter((obj) => {
    const key = getKey(obj);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

export function groupBy<T extends object = object>(
  xs: T[],
  getKey: (x: T) => string
) {
  return xs.reduce(function (rv, x) {
    (rv[getKey(x)] = rv[getKey(x)] || []).push(x);
    return rv;
  }, {} as { [key: string]: T[] });
}

export function mapArrayByKey<T extends Record<string, any>, K extends keyof T>(
  items: T[],
  key: K
): Record<T[K], T> {
  const result: Record<T[K], T> = {} as Record<T[K], T>;
  items.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
}
