import { ParsedOwner } from "../../../features/person/utils/parsePersonUtils";

export function filterUniqAddressesOnLv<
  T extends {
    lv: {
      number: string;
      catastral_territory: {
        id: number;
        name: string;
      };
    };
    parsedOwner: ParsedOwner;
  }
>(lettersAll: T[]) {
  // Eliminate duplicates functional
  const lettersMap = lettersAll.reduce((acc, letter) => {
    const address = `${letter.lv.number}${letter.lv.catastral_territory.id}${letter.parsedOwner?.city} ${letter.parsedOwner?.postcode}, ${letter.parsedOwner?.address}`;
    acc[address] = letter;
    return acc;
  }, {} as { [key: string]: T });

  return Object.values(lettersMap);
}
