import { Box, ChakraProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export type CardProps = {} & ChakraProps;

export const Card: React.FC<PropsWithChildren<CardProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <Box
      bg={"white"}
      shadow={"sm"}
      border={"gray.100"}
      borderWidth={1}
      borderStyle={"solid"}
      rounded={"lg"}
      p={5}
      {...rest}
    >
      {props.children}
    </Box>
  );
};
