import React, { FC, useMemo } from 'react';
import { Box, Button, ButtonProps, chakra, Flex, Grid, Select, useColorModeValue } from '@chakra-ui/react';
import { PaginationMeta, PaginationState } from 'react-use-pagination/dist-types/getPaginationMeta';

const PagButton: FC<
  ButtonProps & { active?: boolean; disabled?: true | undefined }
> = (props) => {
  const activeStyle = {
    bg: useColorModeValue("blue.600", "brand.500"),
    color: useColorModeValue("white", "gray.200"),
  };
  return (
    <Button
      size={"sm"}
      mx={1}
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.700", "gray.200")}
      opacity={props.disabled && 0.6}
      // @ts-ignore
      _hover={!props.disabled && activeStyle}
      cursor={props.disabled && "not-allowed"}
      {...(props.active && activeStyle)}
      {...props}
      active={(props.active || false).toString()}
    >
      {props.children}
    </Button>
  );
};

export type PaginationProps = PaginationState &
  PaginationMeta & {
    setPage: (page: number) => void;
    setNextPage: () => void;
    setPreviousPage: () => void;
    setPageSize: (pageSize: number, nextPage?: number) => void;
    extraPagesToShow?: 1 | 2;
  };

export const Pagination: React.FC<PaginationProps> = ({
  previousEnabled,
  nextEnabled,
  totalPages,
  currentPage,
  setPreviousPage,
  setNextPage,
  setPage,
  totalItems,
  pageSize,
  setPageSize,
  extraPagesToShow = 2,
}) => {
  const pagBg = useColorModeValue("#F9FAFB", "gray.600");
  const uniq = useMemo(() => Math.random(), []);
  const showPerPage = [pageSize, 30, 50, 100, 200, 500];
  return (
    <Grid bg={pagBg} w="full" p={2} gridTemplateColumns={"auto auto auto"}>
      <Flex gap={2} alignItems={"center"} color={"gray.600"}>
        <chakra.span fontSize={"sm"} whiteSpace={"nowrap"}>
          Zobrazit
        </chakra.span>
        <Select
          size={"sm"}
          w={"70px"}
          bg={"white"}
          onChange={(e) => setPageSize(+e.target.value)}
        >
          {showPerPage.map((per) => (
            <option key={uniq + "show" + per} value={per}>
              {per}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex m={"auto"}>
        <PagButton
          onClick={setPreviousPage}
          disabled={!previousEnabled || undefined}
        >
          Předchozí
        </PagButton>
        {Array(Math.min(totalPages, extraPagesToShow * 2 + 1))
          .fill(1)
          .map((_, i) => {
            const current = Math.max(0, currentPage - extraPagesToShow) + i;

            if (current > totalPages - extraPagesToShow) {
              return null;
            }
            return (
              <PagButton
                onClick={() => setPage(current)}
                key={`${uniq}-${i}`}
                active={current === currentPage}
              >
                {current + 1}
              </PagButton>
            );
          })}
        <PagButton
          onClick={() => setNextPage()}
          disabled={!nextEnabled || undefined}
        >
          Další
        </PagButton>
      </Flex>

      <Box
        pt={2}
        color={"gray.600"}
        fontSize={"sm"}
        textAlign={"right"}
        whiteSpace={"nowrap"}
      >
        {pageSize} z {totalItems}
      </Box>
    </Grid>
  );
};
